import React, { useEffect, useState, useRef } from "react";
import NavbarComponent from "../NavbarComponent/NavbarComponent";
import FilterIcon from "../../../assets/images/icons/icon-filter.svg";
import RefreshIcon from "../../../assets/images/icons/downloadredresh.png";
import {
  Row,
  Col,
  Form,
  InputGroup,
  Container,
  Button,
  Popover,
  Overlay,
} from "react-bootstrap";
import "./MainContent.scss";
// filter,throttle,
import { cloneDeep, filter } from "lodash";
import "rc-pagination/assets/index.css";
import { Link } from "react-router-dom";
import CrossIcon from "../../../assets/images/icons/icon-cross.svg";
import EditIcon from "../../../assets/images/icons/icon-edit.svg";
import FilterComponent from "../FilterComponent/FilterComponent";
import {
  abortJobApi,
  getJobsApi,
  getOganisations,
  putNotesApi,
  putPriorityApi,
  resubmitJobApi,
} from "../../../services/HostalabServices";
import { Modal } from "react-bootstrap";
import Warning from "../../../assets/images/Warning.svg";
import {
  Add_Filter,
  Add_Job,
  Delete_Filter,
} from "../../../Redux/Actions/actions";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Loader/Loader";

const tableHead = {
  priotity: "Priority",
  roomId: "Room ID",
  jobId: "Job ID",
  status: "Status",
  claimId: "Claim ID",
  stage: "Stage",
  company: "Company",
  email: "Email",
  roomType: "Room Type",
  numberOfImages: "# of Images",
  submittedTime: "Time submitted",
  timeImageQAd: "Time image QAd",
  timeLabelInit: "Time labelled",
  timeModelled: "Time modelled",
  timeQad: "Time QAd",
  timeProceed: "Time processed",
  durationImageQA: "Time to image QA",
  timeLabel: "Time to label",
  timeModel: "Time to model",
  timeQa: "Time to QA",
  totalTime: "Total time",
  comments: "Comments",
  address: "Address",
};
const MainContent = () => {
  const selector = useSelector((state) => state.JobReducers.jobData);
  const dispatch = useDispatch();
  const buttonref = useRef(null);
  const commentsRef = useRef(null);
  const listInnerRef = useRef();
  const [loader, setLoader] = useState(true);
  const [tableloader, setTableLoader] = useState(false);
  const [filters, setFilters] = useState([]);
  const [allData, setAlldata] = useState([]);
  const [main, setMain] = useState([]);
  const [jobid, setjobid] = useState();
  const [email, setEmail] = useState("");
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState("");
  const [smShow, setSmShow] = useState(false);
  const [collection, setCollection] = useState(cloneDeep(allData));
  const [popup, setPopUp] = useState(false);
  const [visible, setVisible] = useState(false);
  const [currentVisible, setCurrentVisible] = useState(false);
  const [show, setShow] = useState(false);
  const [filterActive, setfilterActive] = useState(false);
  // add comments tooltip state
  const [showComments, setShowComments] = useState(false);
  const [commentsTarget, setCommentsTarget] = useState(null);
  const [radioValue, setRadioValue] = useState("");
  const [notes, setNotes] = useState("");
  const [filterUrl, setFilterUrl] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [organisations, setOrganisations] = useState([]);

  const [selectedRadio, setSelectedRadio] = useState("");
  const [abortPopup, setAbortPopup] = useState(false);
  const [resubmitPopup, setResubmitPopup] = useState(false);
  const [abortJobError, setAbortJobError] = useState("");
  const [abortJobCause, setAbortJobCause] = useState("");
  const [resubmitJobBypass, setResubmitJobBypass] = useState("");

  useEffect(() => {
    jobsCall(`minResults=100&maxDays=7`);
    organisationApi();
    dispatch(Add_Filter([]));
  }, []);

  useEffect(() => {
    setAlldata(selector.length > 0 ? selector[0] : []);
    setMain(selector.length > 0 ? selector[0] : []);
  }, [selector]);

  useEffect(() => {
    filters.length <= 0 ? setVisible(true) : setVisible(false);
    if (filters.length > 0) {
      const filterDatas = [];
      console.log("filters Applied", filters);
      filters.map((fil) => {
        filterDatas.push(`${fil.Type}=${fil.value}`);
      });
      setFilterUrl(filterDatas.join("&"));
    } else if (filterUrl != null) {
      setFilterUrl("");
    }
  }, [filters]);

  useEffect(() => {
    if (searchValue.length > 0) {
      if (filterUrl != "" && filterUrl != null) {
        jobsCall(`${filterUrl}&roomId=${searchValue}`);
      } else if (filterUrl != null) {
        jobsCall(`roomId=${searchValue}`);
      }
    } else {
      if (filterUrl != "" && filterUrl != null) {
        jobsCall(`${filterUrl}`);
      } else if (filterUrl != null) {
        jobsCall(`minResults=100&maxDays=7`);
      }
    }
  }, [filterUrl]);

  useEffect(() => {
    setCollection(cloneDeep(allData));
  }, [allData]);

  const jobsCall = async (data) => {
    getJobsApi(data)
      .then((res) => {
        setLoader(false);
        setTableLoader(false);
        setLastEvaluatedKey(res.LastEvaluatedKey);
        if (data.includes("LastEvaluatedKey")) {
          dispatch(Add_Job([...selector[0], ...res.jobs]));
        } else {
          dispatch(Add_Job(res.jobs));
        }
      })
      .catch((error) => console.log("error", error));
  };

  const organisationApi = () => {
    getOganisations()
      .then((res) => {
        setLoader(false);
        setTableLoader(false);
        let org = [];
        res.map((dat) => {
          org.push(dat.organization);
        });
        setOrganisations(org);
      })
      .catch((error) => console.log("Organisation error", error));
  };

  const loadMore = async () => {
    if (searchValue.length > 0) {
      if (filterUrl != "" && filterUrl != null) {
        await jobsCall(
          `${filterUrl}&roomId=${searchValue}&LastEvaluatedKey=${lastEvaluatedKey}`
        ).then(() => {
          // document.getElementById("table").style.overflowY = "auto";
        });
      } else {
        await jobsCall(
          `roomId=${searchValue}&LastEvaluatedKey=${lastEvaluatedKey}`
        ).then(() => {
          // document.getElementById("table").style.overflowY = "auto";
        });
      }
    } else {
      if (filterUrl != "" && filterUrl != null) {
        await jobsCall(
          `${filterUrl}&LastEvaluatedKey=${lastEvaluatedKey}`
        ).then(() => {
          // document.getElementById("table").style.overflowY = "auto";
        });
      } else {
        await jobsCall(`LastEvaluatedKey=${lastEvaluatedKey}`).then(() => {
          // document.getElementById("table").style.overflowY = "auto";
        });
      }
    }
  };

  const handleClick = () => {
    setShow(!show);
    setfilterActive(!filterActive);
  };

  const searchData = (e) => {
    const search = e.target.value;
    setSearchValue(search);
    const data = [];
    if (search.length > 0) {
      if (filterUrl != "" && filterUrl != null) {
        jobsCall(`${filterUrl}&roomId=${search}`);
      } else {
        jobsCall(`roomId=${search}`);
      }
      // allData.filter((post) => {
      //   if (post.roomId.toLowerCase().includes(search.toLowerCase())) {
      //     data.push(post);
      //   }
      //   return null;
      // });
    } else {
      if (filterUrl != "" && filterUrl != null) {
        jobsCall(`${filterUrl}`);
      } else {
        jobsCall(`minResults=100&maxDays=7`);
      }
    }
    // setAlldata(data);
    // search.length <= 0 && setAlldata(main);
  };

  const headRow = () => {
    return Object.values(tableHead).map((title, index) => (
      <th key={index}>{title}</th>
    ));
  };

  const handleComments = (e) => {
    setShowComments(!showComments);
    setCommentsTarget(e.target);
  };

  const filterDataFromChild = (data) => {
    console.log("datasssssss", data);
    setFilters(data);
  };

  useEffect(() => {
    if (filters.length > 0) {
      console.log("datasssssss filtersssssss", filters);
      applyFilter();
    }
  }, [filters]);

  const Filteration = () => {
    if (filters.length > 0) {
      let datafilter = [];
      main.map((data) => {
        let check = false;
        for (let i = 0; i < filters.length; i++) {
          if (data[filters[i].Type] == filters[i].value) {
            check = true;
          } else {
            check = false;
            break;
          }
        }
        check && datafilter.push(data);
      });
      setAlldata(datafilter);
    } else {
      setAlldata(main);
    }
  };

  const onCancleClick = (e) => {
    e.preventDefault();
    setFilters([]);
    dispatch(Delete_Filter());
    setAlldata(main);
    handleClick();
    setShow(false);
  };

  const applyFilter = (e) => {
    // e.preventDefault();
    dispatch(Add_Filter(filters));
    Filteration();
    handleVisible();
    setVisible(false);
    handleClick();
    setShow(false);
    filters.length <= 0 && setAlldata(main);
  };
  //popup
  const handleVisible = () => {
    setCurrentVisible(true);
  };
  function addZero(i) {
    if (i < 10) {
      i = "0" + i;
    }
    return i;
  }

  const resubmitBypassHandel = (value) => {
    setResubmitJobBypass(value);
  };

  const stripSec = (value) => {
    if (value) {
      let splitedValue = value.split(":");
      splitedValue = splitedValue.slice(0, -1);
      // console.log(splitedValue.join(":"));
      return splitedValue.join(":");
    }
  };

  return (
    <React.Fragment>
      <div className="col px-0">
        <NavbarComponent />
        <Container>
          <div className="dashboard__wrapper">
            <div className="page__title">
              <h2
                onClick={() => {
                  setTableLoader(true);
                  // jobsCall(`minResults=100&maxDays=7`);
                  jobsCall(`${filterUrl}`);
                  organisationApi();
                  // dispatch(Add_Filter([]));
                  // setFilters([]);
                  setSelectedRadio("");
                }}
              >
                Pipeline dashboard{" "}
                <img className="image_refreshicon" src={RefreshIcon} />
              </h2>
            </div>
            <Row>
              <Col xs={12} md={12} sm={12} lg={5} xl={5}>
                <div className="search__filter ps-3 ms-2 d-flex">
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                    }}
                  >
                    <InputGroup className="mb-3">
                      <Form.Control
                        aria-label="Default"
                        aria-describedby="inputGroup-sizing-default"
                        type="search"
                        className="ps-0 search__Input"
                        id="search"
                        placeholder="Search"
                        onChange={searchData}
                      />
                      <span className="tooltiptext">Search by Room ID</span>
                    </InputGroup>
                  </Form>
                  <div>
                    <Button
                      ref={buttonref}
                      className={`filter__btn d-flex justify-content-center align-items-center ms-4 ${
                        filterActive ? "activebtn" : ""
                      }`}
                      onClick={handleClick}
                    >
                      <img
                        src={FilterIcon}
                        alt="filter icon"
                        className="me-3"
                      />
                      Filters
                    </Button>
                    {show ? (
                      <>
                        <div
                          className="filterBackground"
                          onClick={() => {
                            setShow(false);
                            setfilterActive(false);
                          }}
                        ></div>
                        <div
                          className="tooltip-popup"
                          id="popover-contained"
                          onHide={() => {
                            setShow(false);
                            setfilterActive(false);
                          }}
                        >
                          <div className="filter__title">Add filter</div>
                          <div className="dropdown__body">
                            <Form
                              onSubmit={(e) => {
                                e.preventDefault();
                              }}
                            >
                              <FilterComponent
                                filterData={filterDataFromChild}
                                data={main}
                                organisations={organisations}
                                handleVisible={handleVisible}
                                // buttonDisabled={visible}
                                cancleClick={(e) => {
                                  onCancleClick(e);
                                }}
                                // applyClick={(e)=>{applyFilter(e)}}
                              />
                              {/* <div className="popover__footer d-flex justify-content-between my-3 px-3">
                              <button
                                disabled={visible}
                                className="clear__filter"
                                onClick={(e) => {
                                  e.preventDefault();
                                  setFilters([]);
                                  dispatch(Delete_Filter());
                                  setAlldata(main);
                                  handleClick();
                                  setShow(false);
                                }}
                              >
                                Clear all
                              </button>
                              <button
                                // disabled={visible}
                                className="apply__filter"
                                onClick={applyFilter}
                              >
                                Apply Filter
                              </button>
                            </div> */}
                            </Form>
                          </div>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </Col>
              {selectedRadio ? (
                <Col xs={12} md={12} sm={12} lg={7} xl={7}>
                  <div className="d-flex justify-content-end">
                    <Button
                      ref={buttonref}
                      className={` btn-danger d-flex justify-content-center align-items-center ms-4 px-5 ${
                        selectedRadio == "" ? "disabled" : ""
                      }`}
                      onClick={() => {
                        if (selectedRadio != "") {
                          setAbortPopup(true);
                        }
                      }}
                    >
                      Abort
                    </Button>
                    <Button
                      ref={buttonref}
                      className={`btn-primary d-flex justify-content-center align-items-center ms-4 px-5 ${
                        selectedRadio == "" ? "disabled" : ""
                      }`}
                      onClick={() => {
                        if (selectedRadio != "") {
                          setResubmitPopup(true);
                        }
                      }}
                    >
                      Resubmit
                    </Button>
                  </div>
                </Col>
              ) : null}

              <Col xs={12} md={12} sm={6} lg={7} xl={7} className="show-filter">
                {currentVisible && filters.length > 0 && (
                  <div className="d-flex align-items-center  mx-2">
                    <span className="current-filter">Current filters:</span>
                    {filters.map((data, val) => {
                      return (
                        <React.Fragment>
                          <button
                            className="btn filter-processing filter-name mx-2"
                            onClick={() => {
                              // setFilters("");
                              setCollection(cloneDeep(allData));
                            }}
                          >
                            {data.value.length > 5
                              ? data.value.substring(0, 5) + "..."
                              : data.value}
                            <i
                              onClick={() => {
                                setFilters(
                                  filters.filter(
                                    (item) =>
                                      data.Type != item.Type &&
                                      data.value != item.value
                                  )
                                );
                                dispatch(
                                  Add_Filter(
                                    filters.filter(
                                      (item) =>
                                        data.Type != item.Type &&
                                        data.value != item.value
                                    )
                                  )
                                );
                              }}
                            >
                              <img
                                src={CrossIcon}
                                alt="cross icon"
                                className="p-2"
                              />
                            </i>
                          </button>
                        </React.Fragment>
                      );
                    })}
                  </div>
                )}
              </Col>
            </Row>
            {/* <div className="refreshDiv">
              <span
                className="refreshSpan"
                onClick={() => {
                  jobsCall(`minResults=100&maxDays=7`);
                  organisationApi();
                  dispatch(Add_Filter([]));
                  setFilters([]);
                }}
              >
                {" "}
                <img src={RefreshIcon} /> Refresh
              </span>
            </div> */}
            {/* dataTable date end */}
            {collection.length <= 0 && !loader && !tableloader && (
              <div className="nodataloading">
                <p>No Data Found</p>
              </div>
            )}
            {tableloader && (
              <div className="nodataloading">
                <Loader />
              </div>
            )}

            {collection.length <= 0 || loader || tableloader ? null : (
              <div className="container datatable__Listing mt-5 p-0 ">
                <div className="custom-table">
                  <table
                    className="table table-responsive"
                    ref={listInnerRef}
                    id="table"
                  >
                    <thead>
                      <tr>
                        <th style={{ minWidth: 20 }} key="radioTH">
                          &nbsp;
                        </th>
                        {headRow()}
                      </tr>
                    </thead>
                    <tbody className="trhover">
                      {collection.map((post, key) => (
                        <tr key={key}>
                          <td>
                            <input
                              type="radio"
                              name="jobRadioButton"
                              value={post}
                              key={key}
                              id={`key+${key}`}
                              // checked={selectedRadio == post ? true : false}
                              style={{ width: 20, height: 20 }}
                              onClick={(e) => {
                                if (selectedRadio == post) {
                                  document.getElementById(
                                    `key+${key}`
                                  ).checked = false;
                                  setSelectedRadio("");
                                } else {
                                  document.getElementById(
                                    `key+${key}`
                                  ).checked = true;
                                  setSelectedRadio(post);
                                }
                                console.log("post", post + "----------" + key);
                              }}
                            />
                          </td>
                          <td
                            className="priority position-relative"
                            align="center"
                          >
                            <span
                              className="badge"
                              onClick={() => {
                                setSmShow(true);
                                setjobid(post.jobId);
                                setEmail(post.email);
                              }}
                            >
                              {post.priority}
                            </span>
                          </td>
                          <td className="roomId">
                            <Link to="#">{post.roomId}</Link>
                          </td>
                          <td className="jobId">
                            <Link to="#">{post.jobId}</Link>
                          </td>
                          <td className={`${post.status}`}>
                            <div className="status-badge">{post.status}</div>
                          </td>
                          <td className="claimId">
                            <Link to="#">{post.claimId}</Link>
                          </td>
                          <td className="stage">{post.stage}</td>
                          <td className="company">{post.org}</td>
                          <td className="email">{post.email}</td>
                          <td className="roomType">{post.roomType}</td>
                          <td className="images">{post.numberOfImages}</td>
                          <td className="timesubmittedTime">
                            {new Date(post.timeSubmitted).toLocaleDateString()},{" "}
                            {addZero(new Date(post.timeSubmitted).getHours())}:
                            {addZero(new Date(post.timeSubmitted).getSeconds())}
                          </td>
                          {post.timeImageQAd ? (
                            <td className="timeImageQAd">
                              {new Date(post.timeImageQAd).toLocaleDateString()}
                              ,{" "}
                              {addZero(new Date(post.timeImageQAd).getHours())}:
                              {addZero(
                                new Date(post.timeImageQAd).getSeconds()
                              )}
                            </td>
                          ) : (
                            <td className="timeImageQAd">{"--"}</td>
                          )}
                          {post.timeLabelled ? (
                            <td className="timeLabelled">
                              {new Date(post.timeLabelled).toLocaleDateString()}
                              ,{" "}
                              {addZero(new Date(post.timeLabelled).getHours())}:
                              {addZero(
                                new Date(post.timeLabelled).getSeconds()
                              )}
                            </td>
                          ) : (
                            <td className="timeLabelled">{"--"}</td>
                          )}
                          {post.timeModelled ? (
                            <td className="timeModelled">
                              {new Date(post.timeModelled).toLocaleDateString()}
                              ,{" "}
                              {addZero(new Date(post.timeModelled).getHours())}:
                              {addZero(
                                new Date(post.timeModelled).getSeconds()
                              )}
                            </td>
                          ) : (
                            <td className="timeModelled">{"--"}</td>
                          )}
                          {post.timeQAd ? (
                            <td className="timeQad">
                              {new Date(post.timeQAd).toLocaleDateString()},{" "}
                              {addZero(new Date(post.timeQAd).getHours())}:
                              {addZero(new Date(post.timeQAd).getSeconds())}
                            </td>
                          ) : (
                            <td className="timeQad">{"--"}</td>
                          )}
                          {post.timeProceed ? (
                            <td className="timeProceed">
                              {new Date(post.timeProceed).toLocaleDateString()},{" "}
                              {addZero(new Date(post.timeProceed).getHours())}:
                              {addZero(new Date(post.timeProceed).getSeconds())}
                            </td>
                          ) : (
                            <td className="timeProceed">{"--"}</td>
                          )}

                          {/* change */}
                          <td className="durationImageQA">
                            {post.durationImageQA
                              ? stripSec(post.durationImageQA)
                              : "--"}
                          </td>
                          <td className="timeLabel">
                            {post.durationLabel
                              ? stripSec(post.durationLabel)
                              : "--"}
                          </td>
                          <td className="timeModel">
                            {post.durationModel
                              ? stripSec(post.durationModel)
                              : "--"}
                          </td>
                          <td className="timeQa">
                            {post.durationQA ? stripSec(post.durationQA) : "--"}
                          </td>
                          <td className="totalTime">
                            {post.totalTime ? stripSec(post.totalTime) : "--"}
                          </td>
                          <td className="comments" ref={commentsRef}>
                            <button
                              className="edit"
                              key={key}
                              onClick={(e) => {
                                handleComments(e);
                                setNotes(post.notes);
                                setjobid(post.jobId);
                              }}
                            >
                              <p className="notesValue">
                                {post.notes ? post.notes : ""}
                              </p>
                              <img
                                src={EditIcon}
                                alt="edit-icon"
                                className="ms-auto"
                              />
                            </button>
                          </td>
                          <td className="totalTime">
                            {post.address ? post.address : "--"}
                          </td>
                          {/* handle comments tooltip */}
                        </tr>
                      ))}
                      {loader ? (
                        <tr>
                          <td colSpan={Object.values(tableHead).length}>
                            <Loader />
                          </td>
                        </tr>
                      ) : (
                        collection.length > 0 &&
                        lastEvaluatedKey != "" &&
                        lastEvaluatedKey != null &&
                        lastEvaluatedKey != "null" && (
                          <tr>
                            <div className="button__section">
                              <button
                                onClick={() => {
                                  loadMore();
                                  setLoader(true);
                                }}
                              >
                                Load more
                              </button>
                            </div>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                  {collection.length <= 0 && !loader && !tableloader && (
                    <div className="nodataloading">
                      <p>No Data Found</p>
                    </div>
                  )}
                  {tableloader && (
                    <div className="nodataloading">
                      <Loader />
                    </div>
                  )}

                  {/* dataTable date end */}
                </div>
              </div>
            )}
          </div>
        </Container>
      </div>

      {/* handle comments tooltip */}
      <Overlay
        show={showComments}
        target={commentsTarget}
        key={commentsTarget?.key}
        placement="bottom"
        containerPadding={20}
      >
        <Popover className="p-2" id="comments-popover">
          <Popover.Header
            as="h3"
            className="py-3 ps-1 filter__title  border-bottom-0 d-flex justify-content-between"
          >
            Add comments
            <img
              alt="cross icon"
              src={CrossIcon}
              className="d-flex justify-content-end"
              onClick={(e) => {
                e.preventDefault();
                setShowComments(false);
              }}
            />
          </Popover.Header>
          <Popover.Body className="p-0">
            <Form>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Control
                  as="textarea"
                  rows={6}
                  className="text-area-css"
                  defaultValue={notes}
                  onChange={(e) => {
                    setNotes(e.target.value);
                  }}
                />
              </Form.Group>
              <div className="popover__footer d-flex justify-content-between my-3">
                <button
                  className="clear__filter"
                  onClick={(e) => {
                    e.preventDefault();
                    setShowComments(false);
                  }}
                >
                  Cancel
                </button>
                <button
                  className="apply__filter"
                  onClick={(e) => {
                    e.preventDefault();
                    putNotesApi({ id: jobid, notes: notes })
                      .then((res) => {
                        // jobsCall(`minResults=100&maxDays=7`);
                        if (searchValue.length > 0) {
                          if (filterUrl != "" && filterUrl != null) {
                            jobsCall(`${filterUrl}&roomId=${searchValue}`);
                          } else {
                            jobsCall(`roomId=${searchValue}`);
                          }
                        } else {
                          if (filterUrl != "" && filterUrl != null) {
                            jobsCall(`${filterUrl}`);
                          } else {
                            jobsCall(`minResults=100&maxDays=7`);
                          }
                        }
                      })
                      .catch((error) => {
                        alert("Error is", error);
                      });
                    setShowComments(false);
                  }}
                >
                  Save
                </button>
              </div>
            </Form>
          </Popover.Body>
        </Popover>
      </Overlay>
      <Modal
        centered
        size="sm"
        show={smShow}
        onHide={() => setSmShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header className="priority-header">
          <Modal.Title id="example-modal-sizes-title-sm" className="p-title">
            Change Priority
          </Modal.Title>
          <span>Choose the new priority</span>
        </Modal.Header>
        <Modal.Body>
          <Form className="radio__css">
            <div className="radio-css">
              <Form.Check
                type="radio"
                aria-label="radio 1"
                name="default"
                className="mb-1"
                value={1}
                onChange={(e) => setRadioValue(e.target.value)}
              />
              <span className="ms-4 mb-1">1</span>
            </div>
            <div className="radio-css">
              <Form.Check
                type="radio"
                aria-label="radio 2"
                name="default"
                className="mt-1"
                value={0}
                onChange={(e) => setRadioValue(e.target.value)}
              />
              <span className="ms-4 mt-1">0</span>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer className="priority-footer">
          <Button
            variant="link"
            className="btn-cancel"
            onClick={() => setSmShow(false)}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            className="btn-change"
            onClick={() => {
              setPopUp(true);
            }}
          >
            Change Priority
          </Button>
        </Modal.Footer>
      </Modal>
      {popup && (
        <Modal
          centered
          size="sm"
          show={smShow}
          onHide={() => setSmShow(false)}
          aria-labelledby="example-modal-sizes-title-sm"
        >
          <Modal.Header className="priority-header">
            <Modal.Title id="example-modal-sizes-title-sm" className="p-title">
              <img src={Warning} alt="waring" />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="main-warning-body">
            <div className="warning-change">
              <span className="w-parent">Change Priority</span>
            </div>
            <div className="warning-change">
              <span className="w-child">Are you sure you want to change</span>
            </div>
            <div className="warning-change">
              <span className="w-child">the priority this job?</span>
            </div>
          </Modal.Body>
          <Modal.Footer className="priority-footer">
            <Button
              variant="link"
              className="btn-cancel"
              onClick={() => setSmShow(false)}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              className="btn-change"
              onClick={() => {
                putPriorityApi({
                  id: jobid,
                  priority: parseInt(radioValue),
                  email: email,
                })
                  .then((res) => {
                    // jobsCall(`minResults=100&maxDays=7`);
                    if (searchValue.length > 0) {
                      if (filterUrl != "" && filterUrl != null) {
                        jobsCall(`${filterUrl}&roomId=${searchValue}`);
                      } else {
                        jobsCall(`roomId=${searchValue}`);
                      }
                    } else {
                      if (filterUrl != "" && filterUrl != null) {
                        jobsCall(`${filterUrl}`);
                      } else {
                        jobsCall(`minResults=100&maxDays=7`);
                      }
                    }
                  })
                  .catch((error) => {
                    alert("Error is", error);
                  });
                setPopUp(false);
                setSmShow(false);
              }}
            >
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      {/* ABORT popup */}

      {abortPopup && (
        <Modal
          centered
          size="md"
          show={abortPopup}
          onHide={() => setAbortPopup(false)}
          aria-labelledby="example-modal-sizes-title-md"
        >
          <Modal.Header className="priority-header">
            <Modal.Title id="example-modal-sizes-title-md" className="p-title">
              <img src={Warning} alt="waring" />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="main-warning-body">
            <div className="warning-change">
              <span className="w-parent">
                Are you sure you want to abort this Job?
              </span>
            </div>
            {/* <div className="warning-change">
              <span className="w-child">Are you sure you want to change</span>
            </div>
            <div className="warning-change">
              <span className="w-child">the priority this job?</span>
            </div> */}
            <form className="w-75">
              <div className="d-flex flex-column form-group ">
                <label htmlFor="abortError">Error</label>
                <input
                  className="form-control form-control-md"
                  id="abortError"
                  onChange={(e) => {
                    setAbortJobError(e.target.value);
                  }}
                />
                <label htmlFor="abortCause">Cause</label>
                <input
                  className="form-control form-control-md"
                  id="abortCause"
                  onChange={(e) => {
                    setAbortJobCause(e.target.value);
                  }}
                />
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer className="priority-footer">
            <Button
              variant="link"
              className="btn-cancel"
              onClick={() => {
                setAbortJobError("");
                setAbortJobCause("");
                setAbortPopup(false);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              // className={`btn-danger ${abortJobError == "" ? "disabled" : ""}`}
              className={`btn-danger`}
              onClick={() => {
                abortJobApi({
                  id: selectedRadio?.jobId,
                  error: abortJobError,
                  cause: abortJobCause,
                })
                  .then((res) => {
                    if (!res?.error) {
                      console.log("response error", res);
                      if (searchValue.length > 0) {
                        if (filterUrl != "" && filterUrl != null) {
                          jobsCall(`${filterUrl}&roomId=${searchValue}`);
                        } else {
                          jobsCall(`roomId=${searchValue}`);
                        }
                      } else {
                        if (filterUrl != "" && filterUrl != null) {
                          jobsCall(`${filterUrl}`);
                        } else {
                          jobsCall(`minResults=100&maxDays=7`);
                        }
                      }
                    } else {
                      alert(res?.error);
                    }
                  })
                  .catch((error) => {
                    alert("error error", error);
                  });
                setSelectedRadio("");
                setAbortJobError("");
                setAbortJobCause("");
                setAbortPopup(false);
              }}
            >
              Abort
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      {/* RESUBMIT popup */}
      {resubmitPopup && (
        <Modal
          centered
          size="md"
          show={resubmitPopup}
          onHide={() => setResubmitPopup(false)}
          aria-labelledby="example-modal-sizes-title-md"
        >
          {/* <Modal.Header className="priority-header">
            <Modal.Title id="example-modal-sizes-title-md" className="p-title">
              <img src={Warning} alt="waring" />
            </Modal.Title>
          </Modal.Header> */}
          <Modal.Body className="main-warning-body">
            <div className="warning-change">
              <span className="w-parent">
                Please select a bypass option for resubmiting this Job?
              </span>
            </div>
            {/* <div className="warning-change">
              <span className="w-child">Are you sure you want to change</span>
            </div>
            <div className="warning-change">
              <span className="w-child">the priority this job?</span>
            </div> */}
            <form className="w-75">
              <div className="d-flex flex-column form-group justify-content-start align-items-start">
                <div className="d-flex justify-content-start align-items-center">
                  <input
                    type="radio"
                    id="none"
                    value="none"
                    name="resubmitingRadio"
                    className="resubmitingRadioButton"
                    onChange={(e) => {
                      resubmitBypassHandel(e.target.value);
                    }}
                  />
                  <label htmlFor="none">&nbsp; None</label>
                </div>
                <div className="d-flex justify-content-start align-items-center">
                  <input
                    type="radio"
                    id="modelling"
                    value="modelling"
                    name="resubmitingRadio"
                    className="resubmitingRadioButton"
                    onChange={(e) => {
                      resubmitBypassHandel(e.target.value);
                    }}
                  />
                  <label htmlFor="modelling">&nbsp; Modelling</label>
                </div>
                <div className="d-flex justify-content-start align-items-center">
                  <input
                    type="radio"
                    id="labelling"
                    value="labelling"
                    name="resubmitingRadio"
                    className="resubmitingRadioButton"
                    onChange={(e) => {
                      resubmitBypassHandel(e.target.value);
                    }}
                  />
                  <label htmlFor="labelling">&nbsp; Labelling</label>
                </div>
                <div className="d-flex justify-content-start align-items-center">
                  <input
                    type="radio"
                    id="image_qa"
                    value="image_qa"
                    name="resubmitingRadio"
                    className="resubmitingRadioButton"
                    onChange={(e) => {
                      resubmitBypassHandel(e.target.value);
                    }}
                  />
                  <label htmlFor="image_qa">&nbsp; Image QA</label>
                </div>
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer className="priority-footer">
            <Button
              variant="link"
              className="btn-cancel"
              onClick={() => {
                setResubmitJobBypass("");
                setResubmitPopup(false);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              className={`btn-primary  ${
                resubmitJobBypass == "" ? "disabled" : ""
              }`}
              onClick={() => {
                resubmitJobApi({
                  id: selectedRadio?.jobId,
                  bypass: resubmitJobBypass,
                })
                  .then((res) => {
                    if (res?.executionArn) {
                      console.log("response Bypass", res);
                      if (searchValue.length > 0) {
                        if (filterUrl != "" && filterUrl != null) {
                          jobsCall(`${filterUrl}&roomId=${searchValue}`);
                        } else {
                          jobsCall(`roomId=${searchValue}`);
                        }
                      } else {
                        if (filterUrl != "" && filterUrl != null) {
                          jobsCall(`${filterUrl}`);
                        } else {
                          jobsCall(`minResults=100&maxDays=7`);
                        }
                      }
                    } else {
                      alert(
                        res["Error while starting execution"]?.Error?.Message
                      );
                    }
                  })
                  .catch((error) => {
                    alert("error Bypass", error);
                  });
                setResubmitJobBypass("");
                setSelectedRadio("");
                setResubmitPopup(false);
              }}
            >
              Resubmit
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </React.Fragment>
  );
};

export default MainContent;
