import { createRequestFunc } from "./AxiosInstance";

export const getJobsApi = async (data) => {
  return await createRequestFunc("get", `jobs?${data}`);
};
export const putPriorityApi = async (data) => {
  let apidata = {
    priority: data.priority,
    email: data.email,
  };
  return await createRequestFunc("put", `jobs/${data.id}`, apidata);
};
export const putNotesApi = async (data) => {
  return await createRequestFunc("put", `jobs/${data.id}`, {
    notes: data.notes,
  });
};

export const abortJobApi = async (data) => {
  return await createRequestFunc("post", `jobs/${data.id}/abort`, {
    error: data.error,
    cause: data.cause,
  });
};

export const resubmitJobApi = async (data) => {
  return await createRequestFunc(
    "post",
    `jobs/${data.id}/resubmit?bypass=${data.bypass}`,
    {}
  );
};

//Organisation API

export const getOganisations = async () => {
  return await createRequestFunc("get", `orgs`);
};
